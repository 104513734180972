import { decrypt, encrypt } from '@/utils/crypto'

export class storage {
  static local = {
    /**
     * 获取localStorage的值 - 解密
     * @param key
     */
    get (key: string): any {
      const str = localStorage.getItem(key)
      if (str) {
        return JSON.parse(decrypt(str))
      }
    },

    /**
     * 设置localStorage的值 - 加密
     * @param key
     * @param value
     */
    set (key: string, value: any): void {
      localStorage.setItem(key, encrypt(JSON.stringify(value)))
    },
    remove: localStorage.removeItem,
    clear: localStorage.clear,
  }

  static session = {
    /**
     * 获取sessionStorage的值 - 解密
     * @param key
     */
    get (key: string): any {
      const str = sessionStorage.getItem(key)
      if (str) {
        return JSON.parse(decrypt(str))
      }
    },

    /**
     * 设置sessionStorage的值 - 加密
     * @param key
     * @param value
     */
    set (key: string, value: any): void {
      sessionStorage.setItem(key, encrypt(JSON.stringify(value)))
    },
    remove: sessionStorage.removeItem,
    clear: sessionStorage.clear,
  }

  static cookie = {
    /**
     * 获取cookie
     * @param key
     */
    get (key: string): string {
      return this.getAll()[key]
    },

    /**
     * 设置cookie
     * @param key
     * @param value
     * @param expires 日期字符串|日期对象|天数
     * @param path
     */
    set (key: string, value: string, expires?: string | Date | number, path = '/'): void {
      let cookieStr = `${key}=${value};path=${path}`
      if (expires) {
        let date: Date = new Date()
        if (typeof expires === 'number') {
          date.setTime(date.getTime() + expires * 86400000)
        } else {
          date = new Date(expires)
        }
        cookieStr += ';expires=' + date.toUTCString()
      }
      document.cookie = cookieStr
    },

    /**
     * 获取所有cookie
     */
    getAll (): Record<string, string> {
      const cookieStr = document.cookie
      const cookieArr = cookieStr.split(';')
      const cookieObj: Record<string, string> = {}
      cookieArr.forEach(item => {
        const m = item.split('=')
        cookieObj[m[0]] = m[1]
      })
      return cookieObj
    },

    /**
     * 删除cookie
     * @param key
     */
    remove (key: string): void {
      this.set(key, '', -1)
    },
  }
}
