import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/login' },
  {
    path: '/login', name: 'Login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/register', name: 'Register',
    component: () => import('@/views/login/Register.vue')
  },
  {
    path: '/forgot-password', name: '/ForgotPassword',
    component: () => import('@/views/login/ForgotPassword.vue')
  }
]
export default { routes }
