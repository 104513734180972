interface CallbackFn { (): void }
export default {
  /**
   * 防抖 - 延迟触发,多次调用触发最后一次
   * @param fn
   * @param delay
   */
  debounce (fn: CallbackFn, delay = 500) {
    let timer: any
    return function () {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        fn()
      }, delay)
    }
  },

  /**
   * 节流 - 一段时间内只允许触发一次
   * @param fn
   * @param delay
   */
  throttle (fn: CallbackFn, delay = 800) {
    let timer: any
    return function () {
      if (!timer) {
        fn()
        timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
        }, delay)
      }
    }
  }
}
