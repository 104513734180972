import { Directive } from 'vue'

// 元素是否显示
function isShow (el: HTMLElement): boolean {
  if (el.style.display === 'none') {
    return false
  } else if (el !== document.body) {
    return isShow(el.parentNode as HTMLElement)
  } else {
    return true
  }
}

// 被点击的元素是否是element的popper
function isPopper (el: HTMLElement) {
  if (el !== document.body) {
    if (el.classList.value.split(' ').includes('el-popper')) {
      return true
    } else {
      return isPopper(el.parentNode as HTMLElement)
    }
  } else {
    return false
  }
}

const directive: Directive = {
  mounted (el, binding) {
    function click (e: MouseEvent) {
      // 如果该元素以不存在，则销毁监听
      if (document.body.contains(el)) {
        // 如果自身和父级的display不为none，且鼠标点击的不是自身或自身的子节点
        if (isShow(el) && el !== e.target) {
          if (!(el.contains(e.target as Node) || isPopper(e.target as HTMLElement))) {
            binding.value && binding.value()
          }
        }
      } else {
        document.removeEventListener('click', click)
      }
    }

    document.addEventListener('click', click, true)
  },
}

export default directive
