import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/other', component: Layout, redirect: '/other/log/system',
    meta: { title: 'other', icon: 'icon-qita' },
    children: [
      {
        path: 'log/system', name: 'SystemLog',
        meta: { title: 'systemLog', icon: 'icon-caidanlanxitongrizhi' },
        component: () => import('@/views/other/SystemLog.vue'),
      },
      {
        path: 'log/device', name: 'DeviceLog',
        meta: { title: 'deviceLog', icon: 'icon-caidanlanshebeirizhi' },
        component: () => import('@/views/other/DeviceLog.vue'),
      }
    ]
  },
]
export default { routes, menu: true }
