import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/device', component: Layout, redirect: '/device/list/:did',
    meta: { title: 'device', icon: 'el-icon-location' },
    children: [
      {
        path: 'list/:did/share', name: 'ShareDevice',
        meta: { title: 'shareDevice', icon: 'icon-caidanlanshebeiguanli', activeMenu: 'DeviceList' },
        component: () => import('@/views/device/dataShow/ShareDevice.vue'),
      },
      {
        path: 'manage/add', name: 'AddDevice',
        meta: { title: 'addDevice', icon: 'icon-caidanlanshebeiguanli', activeMenu: 'DeviceManage' },
        component: () => import('@/views/device/AddDevice/index.vue'),
      },
      {
        path: 'manage/variable/config/:did', name: 'DeviceVariableConfig',
        meta: { title: 'variableConfig', icon: 'icon-caidanlanshebeiguanli', activeMenu: 'DeviceManage' },
        component: () => import('@/views/variable/VariableConfig.vue'),
      },
      {
        path: 'model/variable/config/:did', name: 'ModelVariableConfig',
        meta: { title: 'variableConfig', icon: 'icon-caidanlanshebeiguanli', activeMenu: 'DeviceModelManage' },
        component: () => import('@/views/variable/VariableConfig.vue'),
      },
    ]
  }
]
export default { routes }
