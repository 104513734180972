import { createModule } from '@/store/modules'

export default createModule({
  namespaced: true,
  state: {
    test: {}
  },
  mutations: {
    setUserInfo (state, val) {
      state.test = val
    }
  },
  actions: {
  },
  getters: {
    getTest: state => {
      return state.test
    }
  }
})
// export default {
//   namespaced: true,
//   state: {
//     userInfo: {}
//   },
//   mutations: {
//     setUserInfo(state, val) {
//       state.userInfo = val
//     }
//   },
//   actions: {
//   },
//   getters: {
//     userInfo: state => state.userInfo
//   }
// }
