import { env } from '@/env'

type UrlConfig = {
  BASE_API: string,
  IMAGE_PREFIX: string,
  WEBSOCKET: string
}
const urlConfig = env()[process.env.NODE_ENV] as UrlConfig
const settings = {
  TITLE: '设备物联',
  ...urlConfig
}

export default settings
