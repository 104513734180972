import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/board', component: Layout, redirect: '/board/workbench',
    meta: { title: 'board', icon: 'icon-kanban' },
    children: [
      {
        path: 'workbench', name: 'Workbench',
        meta: { title: 'workbench', icon: 'icon-caidanlanshouye' },
        component: () => import('@/views/board/cockpit/index.vue'),
      },
      {
        path: 'show-release', redirect: '/show-release',
        meta: { title: 'showConfiguration', icon: 'icon-caidanlanmoxingguanli' },
      },
    ]
  },
  {
    path: '/show-release', name: 'ShowReleaseConfiguration',
    meta: { title: 'showConfiguration', icon: 'icon-caidanlanmoxingguanli', isMenu: false },
    component: () => import('@/views/configuration/ShowReleaseConfiguration.vue'),
  },
]
export default { routes, menu: true }
