exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      BASE_API: 'https://iottest.yuankonginfo.com',
      // BASE_API: 'http://192.168.99.164:15497',
      IMAGE_PREFIX: 'https://sfile.yuankonginfo.com/',
      WEBSOCKET: 'wss://rc.yuankonginfo.com/wss/socket'
    },
    // 生产环境变量
    production: {
      BASE_API: 'https://iottest.yuankonginfo.com',
      IMAGE_PREFIX: 'https://sfile.yuankonginfo.com/',
      WEBSOCKET: 'wss://rc.yuankonginfo.com/wss/socket'
    }
  }
}
