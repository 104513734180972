import request from '@/utils/request'

/**
 * 获取用户信息
 */
export const getUserInfoAPI = () => request.get('/web/userInfo')
/**
 * 编辑用户名
 * @param params
 */
export const editNameAPI = (params: {
  displayName: string,
}) => request.post('/user/updateDisplayName', params)

/**
 * 更换手机号
 * @param params
 */
export const replacePhoneAPI = (params: {
  phone: string,
  code: string
}) => request.post('/user/replacePhone', params)

/**
 * 修改密码
 * @param params
 */
export const updatePasswordAPI = (params: {
  newPassword: string,
  oldPassword: string
}) => request.post('/user/updatePassword', params)
