interface InterceptorConfig {
  token: boolean, notTokenTo?: string, tokenTo?: string
}
const config: Record<string, InterceptorConfig> = {
  default: { token: true, notTokenTo: '/login' },
  '/login': { token: false, tokenTo: '/board/workbench' },
  '/register': { token: false },
  '/forgot-password': { token: false },
  '/error/*': { token: false },
}
export default config
