import moment from 'moment'
moment.locale('zh-cn')

/* eslint-disable */
export enum DateFormat {
  time = 'HH:mm:ss',
  date = 'YYYY-MM-DD',
  datetime = 'YYYY-MM-DD HH:mm:ss'
}
/* eslint-disable */

/**
 * 获取近几天
 * @param day
 */
export function recentDate (day: number): string[] {
  const today = moment()
  const before = moment().add(-day, 'days')
  return [before.format(DateFormat.date), today.format(DateFormat.date)]
}

export default moment
