import router from '@/router'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import settings from '@/settings'
import { auth, role } from '@/router/interceptor'
import store from '@/store'

// 添加路由前置守卫
router.beforeEach(async (to, from, next) => {
  nProgress.start()
  // window.loadingBox.show()
  // 身份验证
  const authResult = auth(to)
  if (authResult.state) {
    // 权限验证
    await role(to).then(res => {
      res ? next() : next('/error/403')
    })
    const { title } = to.meta
    document.title = `${title ? store.getters.languageJSON.menu[title] + ' - ' : ''}${settings.TITLE}`
  } else {
    // window.loadingBox.hide()
    nProgress.done()
    authResult.toPath && next(authResult.toPath)
  }
})

// 添加路由后置守卫
router.afterEach((to, from) => {
  // window.loadingBox.hide()
  nProgress.done()
})
