import { createModule } from '@/store/modules'

export default createModule({
  namespaced: true,
  state: {
    collapse: false, // 折叠
  },
  mutations: {
    setCollapse (state, val) {
      state.collapse = val
    },
    /**
     * 切换收缩状态
     * @param state
     */
    collapseToggle (state) {
      state.collapse = !state.collapse
    }
  },
  actions: {
  },
  getters: {
    collapse: state => state.collapse
  }
})
