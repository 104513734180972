import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/report', component: Layout, redirect: '/report/history',
    meta: { title: 'report', icon: 'icon-baobiao' },
    children: [
      {
        path: 'history', name: 'HistoryDataReport',
        meta: { title: 'historyDataReport', icon: 'icon-caidanlanshujuquxian' },
        component: () => import('@/views/report/HistoryDataReport.vue'),
      },
      {
        path: 'customize', name: 'CustomizeReport',
        meta: { title: 'customizeReport', icon: 'icon-caidanlanshujubaobiao' },
        component: () => import('@/views/report/CustomizeReport.vue'),
      }
    ]
  },
]
export default { routes, menu: true }
