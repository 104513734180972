import store from '@/store'
import { storage } from '@utils/storage'
const languageFiles = require.context('./languageFiles', true, /\.json$/)

export function initLanguage (): void {
  const language = storage.local.get('language') || 'cn'
  setLanguage(language)
}

/**
 * 设置语言
 * @param language
 * @param updateJSON
 */
export function setLanguage (language: string, updateJSON = true): void {
  storage.local.set('language', language)
  if (updateJSON) {
    const value = languageFiles(`./${language}.json`)
    store.commit('setLanguageJSON', value)
  }
}
