import { RouteRecordRaw } from 'vue-router'
import { ObjectUtil } from '@/utils/object-util'
import store from '@/store'
// import { menuModules } from '@/router/modules'
import { menuRoutes as menuModules } from '@/router'

/**
 * 获取菜单列表
 */
export function getMenuList (): RouteRecordRaw[] {
  const menuList = formatMenu(ObjectUtil.copy(menuModules))
  return menuList.filter((menu) => {
    return !(menu.meta?.role && menu.meta?.role !== store.getters.userInfo.role)
  })
}
export type MenuLink = { value: string, path: string }

/**
 * 获取菜单列表 - 面包屑:地址
 * @param condition
 */
export function getMenuLinkList (condition?: string) : Array<MenuLink> {
  const menuList = getMenuList()
  const menuLanguage = store.getters.languageJSON.menu
  const menuLinkList: Array<MenuLink> = []
  for (let i = 0, len = menuList.length; i < len; i++) {
    const menu = menuList[i]
    if (menu.children) {
      for (let j = 0, clen = (menu.children).length; j < clen; j++) {
        const childrenMenu = menu.children[j]
        const value = menuLanguage[<string>menu.meta?.title] + '/' + menuLanguage[<string>childrenMenu.meta?.title]
        if (condition) {
          if (value.toLowerCase().indexOf(condition.toLowerCase()) > -1) {
            menuLinkList.push({ value, path: childrenMenu.path })
          }
        } else {
          menuLinkList.push({ value, path: childrenMenu.path })
        }
      }
    }
  }
  return menuLinkList
}

/**
 * 格式化菜单
 * @param childrenMenu
 * @param parentMenu
 */
function formatMenu (childrenMenu: Array<RouteRecordRaw>, parentMenu?: RouteRecordRaw) {
  for (let i = 0, len = childrenMenu.length; i < len; i++) {
    const menu = childrenMenu[i]
    if (parentMenu) {
      menu.path = parentMenu.path + '/' + menu.path
    }
    if (menu.children && menu.children?.length > 0) {
      formatMenu(menu.children, menu)
    }
  }
  return childrenMenu
}
