import { createModule } from '@/store/modules'

export default createModule({
  namespaced: true,
  state: {
    test: {}
  },
  mutations: {
    setUserInfo (state, val) {
      state.test = val
    }
  },
  actions: {
  },
  getters: {
    getTest: state => {
      return state.test
    }
  }
})
