import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/error/:code',
    name: 'ErrorCode',
    component: () => import('../../views/error/ErrorCode.vue')
  },
  // pathMatch|catchAll
  { path: '/:pathMatch(.*)', redirect: '/error/404' },
]
export default { routes }
