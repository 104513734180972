import { Module, Store } from 'vuex'

export const createModule = <S>(options: Module<S, any>): Store<S> => {
  return <Store<S>>options
}

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('../modules', true, /\.ts$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules: Record<string, any>, modulePath) => {
  // set './app.ts' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  moduleName !== 'index' && (modules[moduleName] = value.default)
  return modules
}, {})

export default modules
