import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/configuration', component: Layout, redirect: '/configuration/manage',
    meta: { title: 'configuration', icon: 'icon-caidanlanmoxingguanli' },
    children: [
      {
        path: 'manage', name: 'ConfigurationManage',
        meta: { title: 'configurationManage', icon: 'icon-caidanlanmoxingguanli' },
        component: () => import('@views/configuration/ConfigurationManage.vue'),
      },
    ]
  },
  {
    path: '/configuration/edit-configuration', name: 'EditConfiguration',
    meta: { title: 'configurationManage', icon: 'icon-caidanlanlishibaojing-1', isMenu: false },
    component: () => import('@views/configuration/EditConfiguration.vue'),
  },
  {
    path: '/configuration/show-configuration', name: 'ShowConfiguration',
    meta: { title: 'configurationManage', icon: 'icon-caidanlanlishibaojing-1', isMenu: false },
    component: () => import('@views/configuration/ShowConfiguration.vue'),
  },
]
export default { routes, menu: true }
