import request from '@/utils/request'

/**
 * 手机号登录,发送短信
 * @param params
 */
export const loginSMSCodeAPI = (params: {
  phone: string,
  code: string
}) => request.post('/sengPhoneCode/getLoginPhoneCode', params)

/**
 * 忘记密码，手机号发送短信
 * @param params
 */
export const resetPasswordSMSCodeAPI = (params: {
  phone: string,
  code: string
}) => request.post('/sengPhoneCode/getResetPasswordPhoneCode', params)
/**
 * 用户信息，发送验证码
 * @param params
 */
export const userInfoSMSCodeAPI = (params: {
  phone: string,
  code: string
}) => request.post('/sengPhoneCode/getUpdatePhoneCode', params)
