import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/inside', component: Layout, redirect: '/inside/customer/manage',
    meta: { title: 'inside', icon: 'icon-neibu', role: 'ROLE_admin' },
    children: [
      {
        path: 'customer/manage', name: 'CustomerManagement',
        meta: { title: 'customerManage', icon: 'icon-caidanlankehuguanli' },
        component: () => import('@/views/inside/CustomerManagement.vue'),
      },
      {
        path: 'assets/manage', name: 'AssetsManage',
        meta: { title: 'assetsManage', icon: 'icon-caidanlanzichanguanli' },
        component: () => import('@/views/inside/AssetsManage.vue'),
      },
      // {
      //   path: 'invoice/audited', name: 'AuditedInvoices',
      //   meta: { title: 'auditedInvoices', icon: 'icon-caidanlanfapiaoshenhe' },
      //   component: () => import('@/views/inside/AuditedInvoices/index.vue'),
      // }
    ]
  }
]
export default { routes, menu: true }
