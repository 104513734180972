import { createStore } from 'vuex'
import modules from '@/store/modules'
import { User } from '@/typings/user'

export type DeviceAuthority = {
  number: { authority: number, updateTime: number }
}

export default createStore({
  state: {
    // 用户信息
    userInfo: {} as User,
    // 缓存设备权限，缓存的权限在一定时间内不会再次从服务器获取
    deviceAuthority: {} as DeviceAuthority,
    languageJSON: {},
    authority: '' as Global.Number
  },
  mutations: {
    setUserInfo (state, value) {
      state.userInfo = value
    },
    setDeviceAuthority (state, value: { deviceId: number, authority: number }) {
      state.deviceAuthority[value.deviceId] = {
        authority: value.authority,
        updateTime: new Date().getTime(),
      }
    },
    setLanguageJSON (state, value) {
      state.languageJSON = value
    },
    setAuthority (state, value) {
      state.authority = value
    }
  },
  actions: {
  },
  getters: {
    userInfo: state => state.userInfo,
    deviceAuthority: state => state.deviceAuthority,
    languageJSON: state => state.languageJSON,
    authority: state => state.authority
  },
  modules
})
